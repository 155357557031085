<template>
  <div class="w-full h-full flex flex-col overflow-x-hidden">
    <div class="px-5 py-4 flex flex-col">
      <fw-heading size="h3" muted>
        <div class="flex gap-1 items-center">
          <div class="flex-1">
            Equipas
            <fw-badge v-if="teams" inline type="simple">{{ teams.length }}</fw-badge>
          </div>
        </div>
      </fw-heading>
    </div>
    <LoadingPlaceholder v-if="loading" size="lg" class="px-4"></LoadingPlaceholder>
    <div v-else-if="teams" class="flex flex-1 flex-col gap-2 overflow-y-auto">
      <RecycleScroller
        ref="sidebarTeamsList"
        v-slot="{ item }"
        :items="teams"
        :item-size="67"
        :buffer="25"
        key-field="key"
        class="px-2"
      >
        <div class="px-2">
          <button
            class="flex px-3 py-2 gap-3 my-1 relative w-full text-left group rounded-lg text-sm items-center"
            :class="{
              'text-primary bg-white shadow-md': item.key == activeTeam,
              'bg-gray-300 bg-opacity-20 hover:text-gray-800': item.key !== activeTeam,
            }"
            @click="$emit('go-to-team', item.key)"
          >
            <div class="w-full">
              <v-clamp v-if="item.name" class="font-semibold" autoresize :max-lines="1">{{ item.name }} </v-clamp>
              <div v-else>Sem nome</div>
              <fw-dot :color="item.has_updates ? 'primary' : 'light'" :ping="false" class="m-1" />
              <div class="flex gap-2 items-end mt-1">
                <div class="flex-1">
                  <div v-if="item.upated_at != null" class="text-xs opacity-80">
                    Atualizada em<br />{{ item.upated_at | formatDateTime }}
                  </div>
                  <div v-if="item.submitted_at != null" class="text-xs opacity-80">
                    Inscrita em<br />{{ item.submitted_at | formatDateTime }}
                  </div>
                  <div v-else class="text-xs opacity-80">Criada em<br />{{ item.created_at | formatDateTime }}</div>
                </div>
                <div>
                  <fw-tag :type="status[item.status].color" size="xs" class="ml-auto">
                    <v-clamp autoresize :max-lines="1">
                      {{ status[item.status].label[language] }}
                    </v-clamp>
                  </fw-tag>
                </div>
              </div>
            </div>
          </button>
        </div>
      </RecycleScroller>
    </div>

    <BlockPagination
      v-if="showPagination"
      :per-page="pagination?.active_limit ?? 25"
      :total="pagination?.total_items ?? 0"
      :total-pages="pagination?.total_pages ?? 0"
      :current="pagination?.current_page ?? 1"
      @page-changed="$emit('page-changed', $event)"
    />
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import { TEAM_STATES } from '@/utils/index.js'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
export default {
  name: 'SidebarTeamsList',
  components: {
    RecycleScroller,
    BlockPagination,
    LoadingPlaceholder,
  },
  props: {
    teams: {
      type: Array,
      default: () => [],
    },
    activeTeam: {
      type: String,
      default: '',
    },
    users: {
      type: Object,
      default: () => {},
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    /*results: {
      type: Object,
      default: () => {},
    },*/
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: TEAM_STATES,
    }
  },
  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },

    showPagination() {
      return this.pagination?.total_pages && this.pagination.total_pages > 1 && !this.loading
    },
  },

  mounted() {
    //scroll to active team
    if (this.activeTeam) {
      const index = this.teams.findIndex(team => team.key == this.activeTeam)
      //this.$refs.sidebarTeamsList.scrollToIndex(index)
      this.$refs.sidebarTeamsList.scrollToItem(index)
    }
  },
}
</script>
