<template>
  <fw-panel :title="'Adicionar membro de equipa'" class="m-5">
    <form class="mb-3" @submit.prevent="addTeamMember">
      <fw-label>Indique um email (externo ou institucional) da pessoa que pretende convidar para a equipa.</fw-label>
      <b-input ref="input" v-model="tmpTeamMember" class="flex-1"></b-input>
      <fw-tip v-if="$v.tmpTeamMember.$error" error>
        <span v-if="!$v.tmpTeamMember.isUnique">O e-mail já faz parte dos convites definidos.</span>
        <span v-else-if="!$v.tmpTeamMember.required">Insira um e-mail da pessoa que pretende convidar.</span>
        <span v-else>O e-mail não é válido.</span>
      </fw-tip>
      <fw-tip v-if="errorMessage" error>{{ errorMessage }}</fw-tip>
    </form>

    <div class="flex items-center justify-between">
      <fw-button type="link-muted" :disabled="saving" @click.native="$emit('close')">
        Fechar
      </fw-button>
      <fw-button type="primary" :loading="saving" @click.native="addTeamMember">
        Adicionar novo membro
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
export default {
  components: {},

  props: {
    team: {
      type: Object,
      default: () => {},
    },
    saving: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tmpTeamMember: '',
      errorMessage: null,
    }
  },

  validations() {
    return {
      tmpTeamMember: {
        required,
        valid: email,
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (value === '') return true

          const existPlayer = this.team.players.find(el => el.email == value || (el.number && el.number == value))
          const existInvite = this.team.invites.find(el => el.email == value || (el.number && el.number == value))
          return !existPlayer && !existInvite
        },
      },
    }
  },

  computed: {},

  mounted() {
    this.$nextTick(() => {
      this.$refs.input.focus()
    })
  },

  methods: {
    setLeader(player) {
      this.team.leader = player
      this.updateTeam()
    },

    async addTeamMember() {
      this.$v.$touch()
      if (this.$v.tmpTeamMember.$invalid) return

      this.$emit('add-team-member', this.tmpTeamMember)
      this.$v.$reset()
      this.tmpTeamMember = ''
    },
  },
}
</script>
